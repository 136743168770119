
import { ref, defineComponent } from 'vue'
import * as api from '../../../utils/api'

export default defineComponent({
	components: {
	},
	data() {
		return {}
	},
	mounted: async function () {
		document.title = 'リダイレクト… - Vets Index'
		try {
			const data = await api.post('/v1/hospital/subscribe/customer_portal', {})
			if (data.url) location.href = data.url
			if (!data.url) throw 'error'
		} catch (e: any) {
			alert('エラー')
		}
	},
	methods: {},
})
